// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Open Sans', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.9;

$headings-font-family: 'Montserrat', sans-serif;

$primary: #edb210;
$secondary: #b08106;

$border-radius: 5px;