// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

body {
	&::-webkit-scrollbar { width: 5px; height: 5px; }
	&::-webkit-scrollbar-track { background: none; }
	&::-webkit-scrollbar-thumb { background: #443d3d; }
	&::-webkit-scrollbar-thumb:hover { background: #000; }
}

.bg-navbar {
    background-color: #dca50d
}

.navbar-top-container {
    width: 100%;

    @include media-breakpoint-up(lg) {
        max-width: 260px;
    }
}

.navbar-toggler {
    float: right;
    margin-top: 18px;
    border: none;
}

.nav-item {
    font-weight: bold;
    letter-spacing: -1px;
    padding-left: 8px;
    padding-right: 8px;

    a {
        &:hover, &.active {
            color: #fff !important;
        }
    }


}

.navbar-brand {
    max-width: 230px;
}

.navbar-nav-main {
    @include media-breakpoint-up(md) {
        min-width: 50%;
    }

}

.highlight {
    padding: 0 10px;
    display: inline-block;
    background: #333;
    border-radius: $border-radius;

    &.primary {
        background-color: $primary;
    }

    &.secondary {
        background-color: $secondary;
    }
}

.underlined {
    display: inline-block;
    padding-bottom: 5px;
    border-bottom: #333 3px solid;

    &.text-primary {
        border-color: $primary;
    }

    &.text-secondary {
        border-color: $secondary;
    }

    &.text-white {
        border-color: white;
    }
}


.landing {

    padding-top: 50px;
    height: auto;
    background: $primary;

    @include media-breakpoint-up(xl) {
        padding-top: 0;
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 550px;
    }

    .social {

        padding: 20px 50px;
        background: $secondary;
        box-sizing: border-box;

        @include media-breakpoint-up(lg) {
            width: 490px;
            height: 98px;
        }

        @include media-breakpoint-up(xl) {
            position: absolute;
            bottom: 0;
            right: 0;
        }

        .heading {
            display: block;
            margin-top: 3px;
            font-family: 'Montserrat', sans-serif;
            font-weight: bold;
        }
    }

    .background-offset {

        @include media-breakpoint-up(xl) {
            position: absolute;
            bottom: -88px;
            width: 100%;
            height: 88px;
            background: #efefef;
        }
    }
}

.features {

    .features-card {
        position: relative;
        color: #fff;
        padding: 2.5rem;
        box-sizing: border-box;
        border-radius: $border-radius;
        background: #363636;

        p { color: #ddd; font-size: 90%; }

        .action {
            position: absolute;
            bottom: -15px;
            left: 0;
            width: 100%;
            height: auto;
            display: flex;
            justify-content: center;
        }
    }
}

.sobre {
    position: relative;
    padding: 60px 0 0 0;
    margin-top: 80px;
    background: #eee;

    @include media-breakpoint-up(lg) {
        margin-top: 200px;
        display: flex;
        align-items: center;
        height: 857px;
    }

    p { font-size: 120%; }

    .background {

        position: relative;
        margin-top: 40px;
        width: 100%;
        height: 400px;
        background: url('../images/sobre-background.jpg') no-repeat center center;
        background-size: cover;

        @include media-breakpoint-up(lg) {
            position: absolute;
            margin-top: 0;
            bottom: 0;
            right: 0;
            width: 45%;
            height: 914px;
        }
    }
}

.diferenciais {
    position: relative;
    display: flex;
    align-items: center;
    background: url('../images/diferencial-background.jpg') no-repeat center right;
    background-size: cover;
    height: 1000px;

    p { font-size: 90%; }
}

.produtos-selecionados {

    .topbar {
        padding: 30px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .nav {
            margin-top: -8px;

            .nav-link { font-size: 85%; }
        }
    }
}

.produto-container {

    background: #f2f2f2;

    .produto-imagem {
        display: block;
        width: 100%;
    }

    .produto-descricao {
        padding: 20px;
        box-sizing: border-box;
    }

    .produto-titulo {
        display: block;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        font-size: .8rem;
        color: #2b2b2b;
    }

    .produto-marca {
        color: #828282;
        font-size: .75rem;
    }

}

footer {

    color: #b9a0a0;
    background: #443d3d;

    .contact-bar {

        position: relative;
        width: 100%;
        height: auto;
        background: #2f2d27;

        .contacts {

            justify-content: space-around;
            width: 100%;

        }

        .contact {
            color: #fff;
            display: flex;
            align-items: center;

            .icon {
                margin-top: 3px;
                margin-right: 30px;
            }

            span {
                display: block;
                font-size: 85%;
                font-weight: bold;
            }

            a {
                display: block;
                font-size: 120%;
                font-weight: bold;
                font-family: 'Montserrat', sans-serif;
                color: $secondary;
                line-height: 1.35rem;
            }
        }
    }

    .footer {
        font-size: 85%;
        text-align: center;

        .col-lg-4 {
            padding-top: 0;
            padding-bottom: 0;
            border-color: #5c4f4f !important;

            @include media-breakpoint-up(lg) {
                padding-top: 100px;
                padding-bottom: 100px;
            }
        }
    }

    .copyright {
        position: relative;
        text-align: center;
        padding: 30px 0;
        border-top: #5c4f4f 1px solid;
        font-size: 80%;

        @include media-breakpoint-up(lg) {
            text-align: left;
        }

        .document {
            margin-top: 40px;

            @include media-breakpoint-up(lg) {
                margin-top: 0;
            }
        }

        .to-top {
            position: absolute;
            top: -25px;
            right: 5%;
            width: 50px;
            height: 50px;
        }
    }
}

.form-label {
    font-size: .85rem;
    margin-bottom: 3px;
    font-weight: bold;
}

.form-control {
    font-size: .85rem;
}

.form-text {
    margin-top: 0;
    color: #999;
    font-size: .75rem;
}

.paragrafo {
    font-size: .85rem;
}

.card-body {
    font-size: .85rem;
}

.x-breadcrumbs {

    .breadcrumb {
        padding: 0;
        font-size: .75rem;
        margin-bottom: 0;
        background: none;
    }

}

.x-titulo {
    padding: 2rem 0;
    background-color: #eee;

    h1 {
        color: $secondary;
        font-weight: bold;
        margin-bottom: 2px;
    }

    span {
        font-size: .85rem;
        color: #666;
    }
}

.x-conteudo {
    padding: 2rem 0;
}

address {
    font-weight: bold;
    letter-spacing: -1px;
}

#google-maps {

    margin-bottom: -10px;

    iframe {
        width: 100%;
        min-height: 600px;
        border: none;
    }
}